<!--
 * @Author: 羚羊公子
 * @FilePath: \llKong\src\pages\wo.vue
-->
<template>
  <div class="wo">
    <van-overlay :show="loading">
      <div class="wrapper">
        <div class="loader">
          <div id="first">
            <div id="second">
              <div id="third"></div>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
    <van-nav-bar title="我的" right-text="☬" @click-right="onClickRight" />
    <van-popup v-model="rightpop" position="right" :style="{ height: '100%' }">
      <div class="brief">
        <div class="brief-title">简要说明</div>
        <p class="brief-sm">
          本网页端由葫芦侠3楼【羚羊公子】完成，后端由葫芦侠3楼大佬【J•S总监】完成！说一下本网页端由VUE框架，及结合Vant的UI组件功能，由于打包后存在跨域问题，所以本网页端仅支持和【J•S总监】大佬的后端在同一目录下，放心不会干扰后端文件！
        </p>
        <hr />
      </div>
    </van-popup>
    <div class="gr">
      <div class="tx">
        <img :src="avatar" alt="" />
      </div>
      <div class="xingx">
        <div class="name">{{ username }}</div>
        <div class="iid"><span class="userid">ID </span>{{ userid }}</div>
      </div>
      <div class="xgzl">
        <span class="yjt1" @click="bjzl">编辑资料</span><span class="yjt2"> <img :src="yjt" alt="" /></span>
      </div>
    </div>
    <div class="youx"><span class="yxname">邮箱：</span>2805049491@qq.com</div>
    <div class="tuic">
      <div class="tuic-name" @click="logout">注销登录</div>
      <div class="tuic-img">
        <img :src="tuiimg" alt="" />
      </div>
    </div>
    <van-action-sheet v-model="xiu" title="请选择修改类型">
      <div class="xuanxiang">
        <div class="xgitem" v-for="(item, index) in actions" :key="index" @click="onCheck(index)">{{ item.name }}</div>
      </div>
    </van-action-sheet>
    <van-popup
      v-model="show1"
      round
      closeable
      close-icon="close"
      close-icon-position="top-right"
      position="top"
      :style="{ height: '30%' }"
    >
      <div class="popText">
        <div class="title">修改头像</div>
        <van-uploader class="sctp" :after-read="onRead" v-model="fileList" multiple :max-count="1" />
        <div class="btn" :class="desd" @click="xgAvatar">{{ btnName }}</div>
      </div>
    </van-popup>
    <van-popup
      v-model="show2"
      round
      closeable
      close-icon="close"
      close-icon-position="top-right"
      position="top"
      :style="{ height: '30%' }"
    >
      <div class="popText">
        <div class="title">修改昵称</div>
        <div class="shuru">
          <input type="text" v-model="name" name="text" class="input" placeholder="输入您要修改的昵称" />
        </div>
        <div class="weigsm">9个字以内，不得包含违规信息</div>
        <div class="btn" @click="xgName">确定</div>
      </div>
    </van-popup>
    <van-popup
      v-model="show3"
      round
      closeable
      close-icon="close"
      close-icon-position="top-right"
      position="top"
      :style="{ height: '30%' }"
    >
      <div class="popText">
        <div class="title">修改邮箱</div>
        <div class="shuru">
          <input type="email" v-model="email" name="text" class="input" placeholder="输入您要修改的邮箱" />
        </div>
        <div class="weigsm">注意邮箱的格式哦！</div>
        <div class="btn" @click="xgEmail">确定</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Notify } from "vant";
import { Dialog } from "vant";
export default {
  name: "wo",
  data() {
    return {
      rightpop: false,
      dlan: false,
      desd: "",
      btnName: "确定",
      loading: false,
      fileList: [],
      imgUrl: "",
      name: "",
      email: "",
      show1: false,
      show2: false,
      show3: false,
      xiu: false,
      avatar: "",
      key: "",
      username: "",
      userid: "",
      yjt: require("../assets/yjt.png"),
      actions: [{ name: "修改头像" }, { name: "修改昵称" }, { name: "修改邮箱" }],
      tuiimg: require("../assets/tc.png"),
    };
  },
  created() {
    let data = {
      key: this.$Base64.decode(sessionStorage.getItem("ktkey")),
      userid: this.$Base64.decode(sessionStorage.getItem("userid")),
    };
    this.$axios
      .post("/request/userinfo.php", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(res => {
        // console.log(res);
        this.avatar = res.data.avatar;
        this.username = res.data.username;
        this.userid = res.data.userid;
      })
      .catch(err => {
        console.log(err);
      });
  },
  methods: {
    onClickRight() {
      this.rightpop = true;
    },
    logout() {
      Dialog.confirm({
        title: "注意",
        message: "你确定要退出登录吗？",
      })
        .then(() => {
          sessionStorage.removeItem("userid");
          setTimeout(() => {
            this.$router.go(0);
          }, 1000);
        })
        .catch(() => {});
    },
    zx() {
      this.$router.push({
        name: "editor",
      });
    },
    bjzl() {
      this.xiu = true;
    },
    onCheck(index) {
      this.xiu = false;
      if (index == 0) {
        this.show1 = true;
      } else if (index == 1) {
        this.show2 = true;
      } else {
        this.show3 = true;
      }
    },
    xgAvatar() {
      if (this.fileList.length != "") {
        let data = {
          key: this.$Base64.decode(sessionStorage.getItem("ktkey")),
          type: "avatar",
          avatar: this.imgUrl,
        };
        this.$axios
          .post("/request/edit_profile.php", data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(res => {
            // console.log(res);
            Notify({ type: "success", message: `${res.data.msg}` });
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        Notify({
          message: "您没有上传头像哦",
          color: "white",
          background: "#f89809",
        });
      }
      this.show1 = false;
      setTimeout(() => {
        this.$router.go(0);
      }, 2000);
    },
    xgName() {
      if (this.name != "") {
        let data = {
          key: this.$Base64.decode(sessionStorage.getItem("ktkey")),
          type: "username",
          username: this.name,
        };
        this.$axios
          .post("/request/edit_profile.php", data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(res => {
            Notify({ type: "success", message: `${res.data.msg}` });
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        Notify({
          message: "昵称不能为空",
          color: "white",
          background: "#f89809",
        });
      }
      this.show2 = false;
      setTimeout(() => {
        this.$router.go(0);
      }, 2000);
    },
    xgEmail() {
      if (this.email != "") {
        let data = {
          key: this.$Base64.decode(sessionStorage.getItem("ktkey")),
          type: "email",
          email: this.email,
        };
        this.$axios
          .post("/request/edit_profile.php", data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(res => {
            Notify({ type: "success", message: `${res.data.msg}` });
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        Notify({
          message: "邮箱不能为空",
          color: "white",
          background: "#f89809",
        });
      }
      this.show3 = false;
      setTimeout(() => {
        this.$router.go(0);
      }, 2000);
    },
    async onRead() {
      this.btnName = "上传中..";
      this.desd = "jybtn";
      const formData = new FormData();
      // 遍历当前临时文件List,将上传文件添加到FormData对象中
      this.fileList.forEach(item => formData.append("file", item.file));
      //   console.log(this.fileList);
      formData.append("key", this.$Base64.decode(sessionStorage.getItem("ktkey")));
      formData.append("tpye", "pic");
      formData.append("content", "twtext");
      // 调用后端接口,发送请求
      await this.$axios
        .post("/request/upload.php", formData, {
          // 因为我们上传了图片,因此需要单独执行请求头的Content-Type
          headers: {
            // 表示上传的是文件,而不是普通的表单数据
            "Content-Type": "multipart/form-data",
          },
        })
        .then(res => {
          if (res.status == 200) {
            this.imgUrl = res.data.url;
            console.log(this.imgUrl);
            this.btnName = "确定";
            this.desd = "";
          } else {
            Notify({
              message: `${res.data.msg}`,
              color: "white",
              background: "#f89809",
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.van-action-sheet__close {
  right: 15px !important;
}
.brief {
  width: 260px;
  height: 100%;
  /* border: 1px red solid; */
  overflow: hidden;
  box-sizing: border-box;
}
.brief-title {
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 40px;
}
.brief-sm {
  width: 100%;
  height: auto;
  padding: 5px 6px;
  font-size: 14px;
  box-sizing: border-box;
}
.xuanxiang {
  width: 90%;
  height: 200px;
  margin: 0 auto;
  /* border: 1px red solid; */
}
.xgitem {
  width: 100%;
  height: 45px;
  line-height: 45px;
  /* border: 1px red solid; */
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 16px;
  background-color: #ebebeb;
  color: #484242;
}
.jybtn {
  pointer-events: none;
}
/* 加载loding */
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loader {
  background-color: rgb(0, 0, 255);
  position: relative;
  width: 2.5em;
  height: 2.5em;
  transform-origin: center;
  transition: 1s;
  border-radius: 50px;
  box-shadow: inset 0px 0px 10px rgb(0, 62, 128), inset 5px 5px 12px rgba(0, 2, 114, 0.8),
    inset 8px 8px 1px rgba(120, 145, 255, 0.7), 0px 0px 1px rgba(120, 136, 255, 0.6);
  animation: 1.2s linear infinite 0s running first682;
}

.loader div {
  width: inherit;
  height: inherit;
  position: absolute;
}

#first {
  transform: rotate(90deg);
}

#first::before {
  --width: 1em;
  --height: 1em;
  content: "";
  position: absolute;
  top: 100%;
  left: calc(50% - var(--width) / 2);
  width: 1em;
  height: 1em;
  background-color: rgb(15, 5, 200);
  box-shadow: inset 5px 5px 10px rgb(99, 102, 255), 0px 0px 2px white;
  border-radius: 50px;
  animation: 0.8s ease-in 0s infinite running jump2;
}

#second {
  transform: rotate(90deg);
}

#second::before {
  --width: 1em;
  --height: 1em;
  content: "";
  position: absolute;
  top: 100%;
  left: calc(50% - var(--width) / 2);
  width: 1em;
  height: 1em;
  background-color: rgb(15, 5, 200);
  box-shadow: inset 5px 5px 10px rgb(99, 102, 255), 0px 0px 2px white;
  border-radius: 50px;
  animation: 1.5s ease-in 0s infinite running jump2;
}

#third {
  transform: rotate(90deg);
}

#third::before {
  --width: 1em;
  --height: 1em;
  content: "";
  position: absolute;
  top: 100%;
  left: calc(50% - var(--width) / 2);
  width: 1em;
  height: 1em;
  background-color: rgb(15, 5, 200);
  box-shadow: inset 5px 5px 10px rgb(99, 102, 255), 0px 0px 2px white;
  border-radius: 50px;
  animation: 1.6s ease-in 0s infinite running jump2;
}

.loader::after {
  --width: 1em;
  --height: 1em;
  content: "";
  position: absolute;
  top: 100%;
  left: calc(50% - var(--width) / 2);
  width: 1em;
  height: 1em;
  background-color: rgb(15, 5, 200);
  box-shadow: inset 5px 5px 10px rgb(99, 102, 255), 0px 0px 2px white;
  border-radius: 50px;
  animation: 1.2s ease-in 1s alternate infinite running jump2;
}

@keyframes first682 {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(0deg);
    transform: rotate(90deg);
  }

  50% {
    transform: rotate(90deg);
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(180deg);
    transform: rotate(270deg);
  }

  100% {
    transform: rotate(270deg);
    transform: rotate(360deg);
  }
}

@keyframes jump2 {
  0% {
    top: 100%;
  }

  25% {
    top: 230%;
  }

  50% {
    top: 100%;
  }

  75% {
    height: 0.6em;
  }

  100% {
    height: 1em;
  }
}

* {
  margin: 0;
  padding: 0;
}
.wo {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  background-color: #f6f6f6;
}
.gr {
  width: 90%;
  height: 70px;
  /* border: 1px red solid; */
  margin: 10px auto;
  display: flex;
  overflow: hidden;
  background-color: #ffffff;
  border-radius: 10px;
}
.tx {
  width: 70px;
  height: 70px;
  overflow: hidden;
  /* border: 1px red solid; */
  overflow: hidden;
  border-radius: 10px;
}
img {
  width: 100%;
  height: 100%;
}
.xingx {
  width: 150px;
  height: 50px;
  /* border: 1px red solid; */
  margin-top: 10px;
  margin-left: 6px;
  overflow: hidden;
}
.name {
  width: fit-content;
  height: 30px;
  line-height: 30px;
  color: rgb(20, 20, 20);
  font-weight: 600;
  /* border: 1px red solid; */
}
.iid {
  width: fit-content;
  height: 20px;
  color: #747474;
  font-size: 14px;
  line-height: 20px;
}
.userid {
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-weight: 600;
  background-color: #cdebffbf;
  text-align: center;
  display: inline-block;
  color: #009cff;
  border-radius: 6px;
  margin-right: 6px;
}
.xgzl {
  width: 110px;
  /* border: 1px red solid; */
  height: 20px;
  line-height: 20px;
  overflow: hidden;
  margin-top: 25px;
  display: flex;
}
.yjt1 {
  width: fit-content;
  height: 20px;
  /* border: 1px red solid; */
  color: #747474;
  margin-left: 20px;
}
.yjt2 {
  width: 20px;
  height: 20px;
  /* border: 1px red solid; */
}
.youx {
  width: 90%;
  height: 40px;
  line-height: 40px;
  padding: 0px 10px;
  margin: 10px auto;
  /* border: 1px rgb(161, 22, 22) solid; */
  background-color: #ffffff;
  border-radius: 10px;
  box-sizing: border-box;
  color: #747474 !important;
}
.yxname {
  font-weight: 600;
  color: rgb(20, 19, 19);
  color: rgb(75 65 65);
}
.tuic {
  width: 90%;
  height: 40px;
  background-color: #ffffff;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin: 0 auto;
}
.tuic-name {
  width: 90%;
  height: 40px;
  line-height: 40px;
  padding-left: 10px;
  box-sizing: border-box;
  font-weight: 600;
  color: #747474;
}
.tuic-img {
  width: 25px;
  height: 25px;
}
img {
  width: 100%;
  height: 100%;
}
/* 上pop 弹出层*/
.popText {
  width: 100%;
  height: 100%;
  /* border: 1px red solid; */
  overflow: hidden;
  box-sizing: border-box;
}
.title {
  width: 60%;
  height: 40px;
  margin: 0 auto;
  /* border: 1px red solid; */
  text-align: center;
  line-height: 40px;
  font-size: 18px;
  color: rgb(0, 0, 0);
  font-weight: 600;
  padding-top: 10px;
}

.shuru {
  width: 90%;
  height: 30px;
  margin: 0 auto;
  /* overflow: hidden; */
  box-sizing: border-box;
}
.input {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 4px;
  box-shadow: 0px 0px 1px 0 rgb(0 0 0 / 20%);
  outline: none;
  color: dimgray;
  box-sizing: border-box;
  text-align: center;
}
.weigsm {
  width: 60%;
  height: 20px;
  margin: 20px auto;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
  letter-spacing: 1px;
  color: #c1c1c1;
}
.btn {
  width: 100px;
  height: 35px;
  margin: 0 auto;
  background-color: rgb(20, 90, 219);
  color: rgb(255, 255, 255);
  text-align: center;
  line-height: 35px;
  border-radius: 6px;
  cursor: pointer;
  user-select: none;
  box-shadow: 0px 0px 8px 2px rgb(22 130 216 / 23%);
}
</style>
