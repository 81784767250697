<!--
 * @Author: 羚羊公子
 * @FilePath: \kongtou\src\pages\send.vue
-->
<template>
  <div class="seedType">
    <van-nav-bar class="dbnav" title="发送类型" left-text="返回" left-arrow @click-left="onClickLeft" />
    <div class="mokuai" @click="sendwz">
      <div class="mkkuang">
        <div class="mokleft">
          <img class="img" :src="wzimg" alt="" />
        </div>
        <div class="mokright">
          <div class="moknr">
            <div class="tpyelx">
              <div class="wznr">文字</div>
              <div class="lxico">
                <img class="img" :src="ico" alt="" />
              </div>
            </div>
            <div class="lxjs">文字最大字数为1000字</div>
          </div>
        </div>
      </div>
    </div>
    <div class="mokuai" @click="sendtp">
      <div class="mkkuang">
        <div class="mokleft">
          <img class="img" :src="tpimg" alt="" />
        </div>
        <div class="mokright">
          <div class="moknr">
            <div class="tpyelx">
              <div class="wznr">图片</div>
              <div class="lxico">
                <img class="img" :src="ico" alt="" />
              </div>
            </div>
            <div class="lxjs">最多可以发送9张图片</div>
          </div>
        </div>
      </div>
    </div>
    <div class="mokuai" @click="sendsp">
      <div class="mkkuang">
        <div class="mokleft">
          <img class="img" :src="spimg" alt="" />
        </div>
        <div class="mokright">
          <div class="moknr">
            <div class="tpyelx">
              <div class="wznr">视频</div>
              <div class="lxico">
                <img class="img" :src="ico" alt="" />
              </div>
            </div>
            <div class="lxjs">视频最大长度为60s</div>
          </div>
        </div>
      </div>
    </div>
    <div class="mokuai" @click="sendsm">
      <div class="mkkuang">
        <div class="mokleft">
          <img class="img" :src="smimg" alt="" />
        </div>
        <div class="mokright">
          <div class="moknr">
            <div class="tpyelx">
              <div class="wznr">私密</div>
              <div class="lxico">
                <img class="img" :src="ico" alt="" />
              </div>
            </div>
            <div class="lxjs">仅自己可见存放专属记忆</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  name: "send",
  data() {
    return {
      wzimg: require("../assets/wz.jpg"),
      tpimg: require("../assets/tp.jpg"),
      spimg: require("../assets/sp.jpg"),
      smimg: require("../assets/simi.jpg"),
      ico: require("../assets/ico.jpg"),
    };
  },
  methods: {
    onClickLeft() {
      this.$router.replace({
        name: "home",
      });
    },
    sendwz() {
      this.$router.push({
        name: "sendwz",
      });
    },
    sendtp() {
      this.$router.push({
        name: "sendtp",
      });
    },
    sendsp() {
      Toast({
        message: "敬请期待",
        icon: require("../assets/logo.png"),
      });
    },
    sendsm() {
      Toast({
        message: "敬请期待",
        icon: require("../assets/logo.png"),
      });
    },
  },
};
</script>

<style scoped>
.seedType {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #f6f6f6;
}
.mokuai {
  width: 93%;
  height: 120px;
  /* border: 1px red solid; */
  background-color: #ffffff;
  margin: 16px auto;
  border-radius: 10px;
  overflow: hidden;
}
.mkkuang {
  width: 90%;
  height: 100px;
  /* border: 1px rgb(0, 4, 255) solid; */
  margin: 10px auto;
  box-sizing: border-box;
  display: flex;
  overflow: hidden;
  justify-content: space-between;
}
.mokleft {
  width: 100px;
  height: 100px;
  /* border: 1px red solid; */
  overflow: hidden;
}
.img {
  width: 100%;
  height: 100%;
}
.mokright {
  width: 55%;
  height: 100px;
  /* border: 1px red solid; */
  box-sizing: border-box;
}
.moknr {
  height: 50px;
  /* border: 1px red solid; */
  margin: 20px auto;
}
.tpyelx {
  display: flex;
  height: 30px;
  /* border: 1px red solid; */
}
.wznr {
  height: 30px;
  line-height: 30px;
  font-weight: 600;
}
.lxico {
  width: 30px;
  height: 30px;
}
.lxjs {
  font-size: 14px;
  color: rgb(107, 107, 107);
}
</style>
