/*
 * @Author: 羚羊公子
 * @FilePath: \kongtou\src\main.js
 */
import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueRouter from 'vue-router'
// 引用路由器
import router from './router/index'
import store from "./store/index"
Vue.config.productionTip = false
import Vant from 'vant';
import 'vant/lib/index.css';
import axios from "axios";
import { Base64 } from 'js-base64'
import { ActionSheet } from 'vant';
Vue.use(ActionSheet);
Vue.use(VueRouter)
Vue.use(Vant);
Vue.prototype.$Base64 = Base64;
// 给全局Vue实例对象添加axios属性
Vue.prototype.$axios = axios;
// 注册饿了么ui
Vue.use(ElementUI);
// 解决编程式路由重复点击报错的问题
let vueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return vueRouterPush.call(this, to).catch(err => err)
}
let vueRouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(to) {
  return vueRouterReplace.call(this, to).catch(err => err)
}


new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
