<!--
 * @Author: 羚羊公子
 * @FilePath: \llKong\src\pages\receive.vue
-->
<template>
  <div>
    <van-nav-bar title="接收空投" left-text="返回" left-arrow @click-left="onClickLeft" />
    <div class="receive">
      <div class="lx">你收到的空投为：<span class="lxsm">图片</span>类型</div>
      <div class="explain">
        空投内容由用户上传，若出现不明链接，请确保你了解并信任空投上传者，否则请不要打开。 如果内容出现违规有害信息，请
        <a href="" class="ts"> 向我们举报 </a>。
      </div>
      <div class="renwu">
        <div class="logo"><img :src="avatar" alt="" /></div>
        <div class="name">
          <div class="username">{{ username }}</div>
          <div class="time" id="date">{{ time }}</div>
        </div>
        <div class="ico">空投</div>
        <div class="look">浏览量：{{ look }}</div>
      </div>
      <div class="context">{{ content }}</div>
      <div class="listImg" :class="yc">
        <div class="tpitem" v-for="(item, index) in itemImg" :key="index">
          <img :src="item" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Notify } from "vant";
export default {
  name: "receive",
  data() {
    return {
      content: "",
      itemImg: [],
      avatar: "",
      username: "",
      time: "",
      look: "",
      yc: "",
    };
  },
  mounted() {
    let data = {
      key: this.$Base64.decode(sessionStorage.getItem("ktkey")),
      postid: sessionStorage.getItem("postid"),
    };
    this.$axios
      .post("/request/detail.php", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(res => {
        console.log(res);
        this.content = res.data.content;
        // console.log(res.data.images);
        let img = res.data.images;
        this.itemImg = img.split(",");
        this.avatar = res.data.sender.avatar;
        this.username = res.data.sender.username;
        this.time = res.data.time;
        this.look = res.data.look;
        if (this.itemImg[0] == "") {
          this.yc = "yc";
        } else {
          this.yc = "";
        }
      })
      .catch(err => {
        // console.log(err);
      });
  },
  methods: {
    onClickLeft() {
      this.$router.replace({
        name: "home",
      });
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.receive {
  width: 100%;
  height: 680px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #f6f6f6;
  /* border: 1px red solid; */
  box-sizing: border-box;
  padding-bottom: 50px;
}
.lx {
  width: 100%;
  height: 30px;
  line-height: 30px;
  padding: 0 16px;
  font-size: 14px;
  color: #080808;
  /* border: 1px red solid; */
}
.lxsm {
  color: rgb(9, 68, 231);
}
.explain {
  width: 100%;
  height: fit-content;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 12px;
  color: #7d7d7d;
  letter-spacing: 1px;
  /* margin-bottom: 16px; */
  box-sizing: border-box;
  border-bottom: 1px rgb(78, 136, 193) dashed;
  padding-bottom: 10px;
}
.ts {
  color: rgb(255, 0, 0);
}
a {
  text-decoration: underline;
}

.renwu {
  width: 95%;
  height: 60px;
  /* border: 2px #ffffff solid; */
  box-sizing: border-box;
  display: flex;
  border-radius: 30px;
  overflow: hidden;
  margin: 10px auto;
  position: relative;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px 0px 11px 0px #e9e9e995;
}

.logo {
  width: 55px;
  height: 55px;
  overflow: hidden;
  border-radius: 50%;
  box-sizing: border-box;
  margin-left: 3px;
}

img {
  width: 100%;
  height: 100%;
}

.name {
  width: auto;
  height: 50px;
  /* border: 1px red solid; */
  margin-left: 8px;
  /* align-items: center; */
}

.username {
  width: auto;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  font-weight: bold;
  color: rgb(21 167 228);
}

.time {
  width: auto;
  height: 20px;
  font-size: 14px;
  color: #6e6e6e;
}

.ico {
  top: 0px;
  right: 0px;
  width: 60px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  position: absolute;
  font-size: 14px;
  /* border-left: 1px rgb(187, 187, 187) solid;
            border-bottom: 1px rgb(164, 164, 164) solid; */
  border-bottom-left-radius: 30px;
  border-top-right-radius: 10px;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  background-color: #51a3ed;
}

.look {
  position: absolute;
  bottom: 0;
  right: 10px;
  width: fit-content;
  height: 20px;
  /* border: 1px red solid; */
  font-size: 12px;
  line-height: 20px;
  padding-right: 6px;
  color: rgb(108 108 108 / 82%);
}
.context {
  /* border-top: 1px rgb(78, 136, 193) dashed; */
  width: 95%;
  box-sizing: border-box;
  height: fit-content;
  padding: 16px 16px;
  color: rgb(67, 67, 67);
  font-size: 14px;
  font-weight: 500;
  background-color: #ffffff;
  border-radius: 6px;
  overflow: hidden;
  margin: 0 auto;
}
.yc {
  display: none !important;
}
.listImg {
  width: 95%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  /* border: 1px red solid; */
  margin: 10px auto;
  overflow: hidden;
  justify-content: space-between;

  align-items: center;
}
.tpitem {
  width: 100%;
  height: 160px;
  border-radius: 6px;
  /* border: 1px red solid; */
  overflow: hidden;
  margin-bottom: 16px;
  background-color: #ffffff;
}
img {
  width: 100%;
  height: fit-content;
}
</style>
