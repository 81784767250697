<!--
 * @Author: 羚羊公子
 * @FilePath: \kongtou\src\pages\share.vue
-->
<template>
  <div class="share">
    <van-nav-bar class="dbnav" title="分享空投" left-text="返回" left-arrow @click-left="onClickLeft" />
    <div class="fxm">{{ qujma }}</div>
    <div class="fxwzjs">输入上方「取件码」领取空投</div>
    <div class="ewqr">
      <vue-qr :dotScale="1" :text="imgUrl" draggable="false" :correctLevel="3" :size="180" :logoScale="0.2"> </vue-qr>
    </div>
    <div class="smjr">扫码进入</div>
    <div class="qtbtn">
      <div class="ebtn" @click="wx">邮件转发</div>
      <div class="cbtn" @click="onCopy" data-clipboard-action="copy" :data-clipboard-text="shareTxet">复制文本</div>
    </div>
    <div class="jtfx">也可将此页面截图分享到朋友圈</div>
  </div>
</template>
<script>
/*
    text 二维码，即扫描二维码后跳转的页面
    size 二维码大小
    margin 二维码图像的外边距, 默认 20px
    bgSrc 嵌入的背景图地址
    logoSrc 嵌入至二维码中心的 LOGO 地址
    logoScale 中间图的尺寸
    dotScale 二维码的点的大小
    colorDark 实点的颜色（注意：和colorLight一起设置才有效）
    colorLight 空白的颜色（注意：和colorDark一起设置才有效）
    autoColor 若为 true, 背景图的主要颜色将作为实点的颜色, 即 colorDark,默认 true
*/
import VueQr from "vue-qr";
import Clipboard from "clipboard";
import { Notify } from "vant";
import { Toast } from "vant";

export default {
  name: "",
  components: {
    VueQr,
  },
  mounted() {
    // console.log(this.imgUrl);
  },
  data() {
    return {
      shareTxet: `取件码「${sessionStorage.getItem(
        "postid"
      )}」快来领取我给你的专属空投把！链接：http://kt.lygzblog.cn/request/web.php?postid=${sessionStorage.getItem(
        "postid"
      )}`,
      imgUrl: "http://kt.lygzblog.cn/request/web.php?postid=" + sessionStorage.getItem("postid"),
    };
  },
  computed: {
    qujma() {
      return sessionStorage.getItem("postid");
    },
  },
  methods: {
    wx() {
      Toast({
        message: "敬请期待",
        icon: require("../assets/logo.png"),
      });
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    onCopy() {
      let clipboard = new Clipboard(".cbtn");
      clipboard.on("success", e => {
        // console.log("复制成功");
        Notify({ type: "success", message: "复制成功快去分享吧！" });
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", e => {
        Notify({
          message: "非常抱歉您的浏览器不支持复制！",
          color: "white",
          background: "#f89809",
        });
        // 释放内存
        clipboard.destroy();
      });
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.share {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: #f6f6f6;
}
.ewqr {
  width: fit-content;
  height: fit-content;
  /* border: 1px red solid; */
  background-color: rgb(255, 255, 255);
  box-sizing: border-box;
  margin: 0 auto;
  border-radius: 10px;
  overflow: hidden;
}
.fxm {
  width: 100%;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 28px;
  font-weight: 600;
  margin-top: 30px;
  color: #3593f8;
}
.fxwzjs {
  width: 100%;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-weight: 500;
  color: #8f8f8f;
  /* border: 1px red solid; */
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.smjr {
  width: 100%;
  text-align: center;
  height: 20px;
  line-height: 20px;
  margin-top: 10px;
  color: #8f8f8f;
}
.qtbtn {
  width: 90%;
  height: 35px;
  /* border: 1px red solid; */
  display: flex;
  justify-content: space-around;
  margin: 20px auto;
}
.ebtn,
.cbtn {
  width: 100px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  background-color: #3593f8;
  color: rgb(255, 255, 255);
  border-radius: 3px;
  user-select: none;
  cursor: pointer;
  font-weight: 500;
}
.jtfx {
  margin-top: 40px;
  width: 100%;
  height: 20px;
  line-height: 20px;
  text-align: center;
  color: #8f8f8f;
}
</style>
