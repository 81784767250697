<!--
 * @Author: 羚羊公子
 * @FilePath: \llKong\src\pages\register.vue
-->
<template>
  <div class="register">
    <van-overlay :show="show">
      <div class="wrapper">
        <div class="loader">
          <div id="first">
            <div id="second">
              <div id="third"></div>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
    <div class="bjt">
      <img :src="bjt" alt="" />
    </div>
    <div class="zhuti">
      <div class="zctitle">小空投</div>
      <div class="smwz">带你链接世界有缘人，快来试一试吧看看和谁心有灵犀！</div>
      <div class="zcbd">
        <div class="input-group">
          <form>
            <input required="" type="text" name="text" autocomplete="off" class="input" v-model.trim="user" />
            <label class="user-label">请输入账号</label>
          </form>
        </div>
      </div>
      <div class="zcbd">
        <div class="input-group">
          <form>
            <input required="" type="password" name="text" autocomplete="off" class="input" v-model.trim="pass1" />
            <label class="user-label">请输入密码</label>
          </form>
        </div>
      </div>
      <div class="zcbd">
        <div class="input-group">
          <form>
            <input required="" type="password" name="text" autocomplete="off" class="input" v-model.trim="pass2" />
            <label class="user-label">请再次输入密码</label>
          </form>
        </div>
      </div>
      <div class="zwf"></div>
      <div class="submit" @click="register">立即注册</div>
      <div class="qwdl">
        <div class="weng" @click="goLogin">前往登录</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Notify } from "vant";

export default {
  name: "register",
  data() {
    return {
      bjt: require("../assets/bj.jpg"),
      user: "",
      pass1: "",
      pass2: "",
      show: false,
    };
  },
  methods: {
    register() {
      if (this.user == "" || this.pass1 == "") {
        Notify({
          message: "输入框不能为空！",
          color: "white",
          background: "#f89809",
        });
      } else if (this.user.length < 8 || this.pass1.length < 8 || this.pass2.length < 8) {
        Notify({
          message: "账号密码最少不能低于8位数！",
          color: "white",
          background: "#f89809",
        });
      } else if (this.user.length > 12 || this.pass1.length > 12 || this.pass2.length > 12) {
        Notify({
          message: "账号密码不能超过12位数！",
          color: "white",
          background: "#f89809",
        });
      } else if (this.pass1 != this.pass2) {
        Notify({
          message: "两次密码输入不一致！",
          color: "white",
          background: "#f89809",
        });
      } else {
        this.show = true;
        let data = {
          user: this.user,
          pass: this.pass1,
        };
        this.$axios
          .post("/request/register.php", data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(res => {
            console.log(res);
            this.show = false;
            if (res.data.code == 500) {
              Notify({ type: "danger", message: `${res.data.msg}` });
            } else {
              Notify({ type: "success", message: `${res.data.msg}` });
            }
          })
          .catch(err => {
            this.show = false;
            console.log(err);
            Notify({ type: "danger", message: `${err.data.msg}` });
          });
      }
    },
    goLogin() {
      this.$router.replace({
        name: "login",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
.zhuti {
  width: 100%;
  height: auto;
  margin-top: 160px;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

/* From uiverse.io by @Navarog21 */
.loader {
  background-color: rgb(0, 0, 255);
  position: relative;
  width: 2.5em;
  height: 2.5em;
  transform-origin: center;
  transition: 1s;
  border-radius: 50px;
  box-shadow: inset 0px 0px 10px rgb(0, 62, 128), inset 5px 5px 12px rgba(0, 2, 114, 0.8),
    inset 8px 8px 1px rgba(120, 145, 255, 0.7), 0px 0px 1px rgba(120, 136, 255, 0.6);
  animation: 1.2s linear infinite 0s running first682;
}

.loader div {
  width: inherit;
  height: inherit;
  position: absolute;
}

#first {
  transform: rotate(90deg);
}

#first::before {
  --width: 1em;
  --height: 1em;
  content: "";
  position: absolute;
  top: 100%;
  left: calc(50% - var(--width) / 2);
  width: 1em;
  height: 1em;
  background-color: rgb(15, 5, 200);
  box-shadow: inset 5px 5px 10px rgb(99, 102, 255), 0px 0px 2px white;
  border-radius: 50px;
  animation: 0.8s ease-in 0s infinite running jump2;
}

#second {
  transform: rotate(90deg);
}

#second::before {
  --width: 1em;
  --height: 1em;
  content: "";
  position: absolute;
  top: 100%;
  left: calc(50% - var(--width) / 2);
  width: 1em;
  height: 1em;
  background-color: rgb(15, 5, 200);
  box-shadow: inset 5px 5px 10px rgb(99, 102, 255), 0px 0px 2px white;
  border-radius: 50px;
  animation: 1.5s ease-in 0s infinite running jump2;
}

#third {
  transform: rotate(90deg);
}

#third::before {
  --width: 1em;
  --height: 1em;
  content: "";
  position: absolute;
  top: 100%;
  left: calc(50% - var(--width) / 2);
  width: 1em;
  height: 1em;
  background-color: rgb(15, 5, 200);
  box-shadow: inset 5px 5px 10px rgb(99, 102, 255), 0px 0px 2px white;
  border-radius: 50px;
  animation: 1.6s ease-in 0s infinite running jump2;
}

.loader::after {
  --width: 1em;
  --height: 1em;
  content: "";
  position: absolute;
  top: 100%;
  left: calc(50% - var(--width) / 2);
  width: 1em;
  height: 1em;
  background-color: rgb(15, 5, 200);
  box-shadow: inset 5px 5px 10px rgb(99, 102, 255), 0px 0px 2px white;
  border-radius: 50px;
  animation: 1.2s ease-in 1s alternate infinite running jump2;
}

@keyframes first682 {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(0deg);
    transform: rotate(90deg);
  }

  50% {
    transform: rotate(90deg);
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(180deg);
    transform: rotate(270deg);
  }

  100% {
    transform: rotate(270deg);
    transform: rotate(360deg);
  }
}

@keyframes jump2 {
  0% {
    top: 100%;
  }

  25% {
    top: 230%;
  }

  50% {
    top: 100%;
  }

  75% {
    height: 0.6em;
  }

  100% {
    height: 1em;
  }
}
.register {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  position: relative;
  background-color: rgb(255, 255, 255);
}
.bjt {
  position: absolute;
  top: 0;
  right: 0;
  width: 200px;
  height: 100px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
}
.zctitle {
  margin-top: 10px;
  width: 100%;
  height: 40px;
  line-height: 50px;
  text-align: center;
  color: #1789fb;
  font-size: 30px;
  font-weight: 600;
}
.smwz {
  margin-top: 10px;
  letter-spacing: 1px;
  width: 100%;
  height: 20px;
  color: #cacaca;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
}

.zcbd {
  width: 80%;
  height: fit-content;
  // border: 1px red solid;
  margin: 6px auto;
  display: flex;
  padding-top: 10px;
}

.input-group {
  width: 100%;
  margin: 0px auto;
  position: relative;

  .input {
    box-sizing: border-box;
    width: 100%;
    border: solid 1.5px #818181;
    border-radius: 1rem;
    background: none;
    padding: 0.7rem 10px;
    font-size: 16px;
    color: #323232;
    transition: border 150ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .user-label {
    position: absolute;
    left: 15px;
    color: #4c4c4c;
    pointer-events: none;
    transform: translateY(0.7rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .input:focus,
  input:valid {
    outline: none;
    border: 1.5px solid #1789fb;
  }

  .input:focus ~ label,
  input:valid ~ label {
    transform: translateY(-50%) scale(0.8);
    background-color: #fffffff1;
    border-radius: 6px;
    padding: 2px 8px;
    font-size: 14px;
    color: #1789fb;
  }
}
.zwf {
  height: 20px;
  width: 100%;
}
.submit {
  width: 80%;
  height: 40px;
  color: rgb(255, 255, 255);
  background-color: #1789fb;
  line-height: 40px;
  text-align: center;
  margin: 0 auto;
  border-radius: 10px;
  user-select: none;
  cursor: pointer;
  font-weight: 600;
}
.submit:active {
  background-color: #1789fb;
}
.qwdl {
  width: 100%;
  height: 30px;
  // border: 1px red solid;
  box-sizing: border-box;
  padding: 0 20px;
  margin-top: 20px;
  position: relative;
}
.weng {
  width: 80px;
  height: 25px;
  user-select: none;
  cursor: pointer;
  line-height: 25px;
  position: absolute;
  right: 0;
  text-align: center;
  bottom: 0;
  border-top: 1px #046bd2 solid;
  border-left: 1px #046bd2 solid;
  border-bottom: 1px #046bd2 solid;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #046bd2;
  color: rgb(255, 255, 255);
}
</style>
