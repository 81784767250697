<!--
 * @Author: 羚羊公子
 * @FilePath: \kongtou\src\pages\lygz.vue
-->
<template>
  <div class="home">
    <div class="content">
      <router-view />
    </div>
    <van-tabbar v-model="active" route>
      <van-tabbar-item replace to="/lygz/home">
        <span>首页</span>
        <template #icon="props">
          <img :src="props.active ? icon.activeHome : icon.home" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/lygz/wo">
        <span>我的</span>
        <template #icon="props">
          <img :src="props.active ? icon.activeWo : icon.wo" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
    <!-- <van-tabbar route>
      <van-tabbar-item replace to="/lygz/home" icon="home-o">标签</van-tabbar-item>
      <van-tabbar-item replace to="/search" icon="search">标签</van-tabbar-item>
    </van-tabbar> -->
  </div>
</template>

<script>
export default {
  name: "lygz",
  data() {
    return {
      active: 0,
      icon: {
        home: require("../assets/sy1.png"),
        activeHome: require("../assets/sy2.png"),
        wo: require("../assets/wo.png"),
        activeWo: require("../assets/wo2.png"),
      },
    };
  },
  methods: {},
};
</script>

<style scoped>
.home {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.content {
  width: 100%;
  /* border: 1px rgb(4, 255, 0) solid; */
  height: 100%;
  overflow: hidden;
  background-color: white;
}
.van-tabbar {
  background-color: rgb(255, 255, 255) !important;
  box-shadow: -2px 15px 6px 0px #a0a0a09e, -3px 6px 7px 2px #c5c5c5;
}
</style>
