/*
 * @Author: 羚羊公子
 * @FilePath: \kongtou\src\store\index.js
 */
//这里引入vuex文件
import Vue from "vue"
import Vuex from "vuex"
Vue.use(Vuex)
export default new Vuex.Store({
    state: {//数据状态
        userid: sessionStorage.getItem("userid"),
        ktkey: sessionStorage.getItem("ktkey"),
        postid: ''

    },
    actions: {//行为，动作

    },
    mutations: {//修改
        add(state, data) {
            state.postid = data
            console.log(state.postid);
        }
    },

    getters: {

    },
})