/*
 * @Author: 羚羊公子
 * @FilePath: \kongtou\src\router\index.js
 */
// 路由文件
import vueRouter from 'vue-router';
import lygz from "../pages/lygz.vue"
import register from "../pages/register.vue"
import login from "../pages/login.vue"
import home from "../pages/home.vue"
import receive from "../pages/receive.vue"
import send from "../pages/send.vue"
import wo from "../pages/wo.vue"
import Sendwz from "../pages/Sendwz.vue"
import Sendtp from "../pages/Sendtp.vue"
import share from "../pages/share.vue"
import editor from "../pages/editor.vue"
import { Notify } from "vant";
import { Base64 } from 'js-base64'
import axios from "axios";
const router = new vueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            redirect: '/lygz/home',
        },
        {
            name: 'receive',
            path: '/receive',
            component: receive,
            meta: {
                title: '接收空投',
                isAuth: true
            },
        },
        {
            name: 'send',
            path: '/send',
            component: send,
            meta: {
                title: '选择发送类型',
                isAuth: true
            },
        },
        {
            name: 'sendwz',
            path: '/sendwz',
            component: Sendwz,
            meta: {
                title: '发送文字空投',
                isAuth: true
            },
        },
        {
            name: 'sendtp',
            path: '/sendtp',
            component: Sendtp,
            meta: {
                title: '发送图片空投',
                isAuth: true
            },
        },
        {
            name: 'share',
            path: '/share',
            component: share,
            meta: {
                title: '分享空投',
                isAuth: true
            },
        },
        {
            name: 'editor',
            path: '/editor',
            component: editor,
            meta: {
                title: '编辑个人信息',
                isAuth: true
            },
        },
        {
            name: 'lygz',
            path: '/lygz',
            component: lygz,
            children: [
                {
                    name: 'home',
                    path: 'home',
                    component: home,
                    meta: {
                        title: '链路空投-链接世界有缘人！',
                        isAuth: false
                    },
                },

                {
                    name: 'wo',
                    path: 'wo',
                    component: wo,
                    meta: {
                        title: '个人中心',
                        isAuth: true
                    },
                },
            ]
        },
        {
            name: 'register',
            path: '/register',
            component: register,
            meta: {
                title: '注册链路空投',
            },

        },
        {
            name: 'login',
            path: '/login',
            component: login,
            meta: {
                title: '登录链路空投',
            },
        },

    ],
})
router.beforeEach((to, from, next) => {
    // console.log(to, from);
    if (to.meta.isAuth) {
        /* let data = {
            user: Base64.decode(sessionStorage.getItem("user")),
            pass: Base64.decode(sessionStorage.getItem("pass"))
        }
        axios.post('/lygz/request/login.php', data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
            .then((res) => {
                console.log(res);
                //判断是否需要鉴权
                let StorageId = Base64.encode(res.data.userid)
                if (sessionStorage.getItem('userid')) {
                    next()
                } else {
                    Notify({
                        message: "请先登录!!!",
                        color: "white",
                        background: "#f89809",
                    });
                    next('/login')
                }
            })
            .catch((err) => {
                console.log(err);
            }) */
        //判断是否需要鉴权
        if (sessionStorage.getItem('userid')) {
            next()
        } else if (sessionStorage.getItem('userid') == null) {
            Notify({
                message: "请先登录!!!",
                color: "white",
                background: "#f89809",
            });
            next('/login')
        } else {
            Notify({
                message: "请先登录!!!",
                color: "white",
                background: "#f89809",
            });
            next('/login')
        }

    } else {
        next()
    }
})

router.afterEach((to, from, next) => {
    // console.log(to, from)
    document.title = to.meta.title || '链路-为你链接世界！';
})

export default router