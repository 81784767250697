<!--
 * @Author: 羚羊公子
 * @FilePath: \kongtou\src\pages\home.vue
-->
<template>
  <div class="sy">
    <van-nav-bar title="小空投" />
    <van-notice-bar
      color="#1989fa"
      background="#ecf9ff"
      left-icon="volume-o"
      text="链路空投---带你链接世界有缘人，快来试一试吧看看和谁心有灵犀！"
    />
    <div class="title">小空投</div>
    <div class="jies">人生该走的弯路，其实一米都不会少</div>
    <div class="send"><button @click="send" class="custom-btn btn-2">发送</button></div>
    <div class="receive"><button @click="showPopup" class="custom-btn btn-3">接收</button></div>
    <van-popup
      v-model="show"
      closeable
      close-icon="close"
      close-icon-position="top-right"
      position="bottom"
      :style="{ height: '40%' }"
    >
      <div class="poptitle">取件码</div>
      <div class="input-group">
        <input autocomplete="off" placeholder="请输入取件码..." name="text" class="input" v-model="qjm" />
      </div>
      <div class="sming">发送空投时，会产生一个5~6位的取件码</div>
      <div class="jiesou" @click="receive">接收</div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "home",
  data() {
    return {
      show: false,
      qjm: "",
    };
  },
  methods: {
    showPopup() {
      this.show = true;
    },
    send() {
      this.$router.replace({
        name: "send",
      });
    },
    receive() {
      console.log(this.qjm);
      sessionStorage.setItem("postid", this.qjm);
      this.$router.push({
        name: "receive",
      });
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.sy {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #f6f6f6;
}
.poptitle {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  /* border: 1px red solid; */
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 600;
  color: rgb(43 43 43);
  margin-bottom: 20px;
  position: relative;
}
.sming {
  width: 100%;
  height: 20px;
  /* border: 1px red solid; */
  text-align: center;
  line-height: 20px;
  font-size: 14px;
  color: #ff4704;
  margin: 10px auto;
}
.jiesou {
  width: 100px;
  height: 35px;
  /* border: 1px #4391f3ed solid; */
  background-color: #1056e4f8;
  text-align: center;
  line-height: 35px;
  position: absolute;
  bottom: 30px;
  right: 30px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  border-radius: 6px;
}

.jiesou:active {
  background-color: #1a2febf8;
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  background-color: #39a9ed;
  cursor: pointer;
  user-select: none;
}
.van-popup {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  overflow: hidden;
}
.van-icon-cross:before {
  content: "\e690" !important;
}
.van-nav-bar__content {
  background-color: white !important;
  box-shadow: 1px -1px 0px 0px #a0a0a09e, 1px -7px 7px 2px #c5c5c5;
}
.van-nav-bar__title {
  color: #3d3d3d !important;
  text-shadow: 0px 0px 1px #3d3d3d;
}
.van-overlay {
  background-color: rgb(0 0 0 / 14%) !important;
}

.title {
  width: 100%;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 28px;
  font-weight: 600;
  color: #4391f3;
  margin-top: 30%;
}
.jies {
  width: 100%;
  text-align: center;
  font-size: 16px;
  color: #bfbfbfd1;
  margin-top: 20px;
  margin-bottom: 80px;
}

.send,
.receive {
  width: 150px;
  margin: 10px auto;
}
.custom-btn {
  width: 150px;
  height: 45px;
  color: #fff;
  border-radius: 5px;
  padding: 10px 25px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5), 7px 7px 20px 0px rgba(0, 0, 0, 0.1),
    4px 4px 5px 0px rgba(0, 0, 0, 0.1);

  font-size: 15px;
}

.btn-2 {
  background: #1789fb;
  background: linear-gradient(0deg, #004dff 0%, #004dff 100%);
  border: none;
}

.btn-2:before {
  height: 0%;
  width: 2px;
}

.btn-2:active {
  box-shadow: 4px 4px 6px 0 rgb(255 255 255 / 50%), 1px -1px 20px 0 rgb(116 125 136 / 50%),
    inset -4px -4px 6px 0 rgb(255 255 255 / 20%), inset 4px 4px 6px 0 rgb(0 0 0 / 40%);
  /* box-shadow: 4px 4px 6px 0 rgb(255 255 255 / 50%), -1px -2px 2px 0px rgb(116 125 136 / 50%),
    inset 0px 0px 19px 0px rgb(255 255 255 / 20%), inset 0px -1px 6px 0px rgb(0 0 0 / 40%); */
}
.btn-3 {
  background: #f6f6f6;
  /* background: linear-gradient(0deg, #004dff 0%, #8da7e4 100%); */
  border: none;
  color: #1789fb;
}

.btn-3:before {
  height: 0%;
  width: 2px;
}

.btn-3:active {
  box-shadow: 4px 4px 6px 0 rgb(255 255 255 / 50%), 1px -1px 20px 0 rgb(116 125 136 / 50%),
    inset -4px -4px 6px 0 rgb(255 255 255 / 20%), inset 4px 4px 6px 0 rgb(0 0 0 / 40%);
  /* box-shadow: 4px 4px 6px 0 rgb(255 255 255 / 50%), -1px -2px 2px 0px rgb(116 125 136 / 50%),
    inset 0px 0px 19px 0px rgb(255 255 255 / 20%), inset 0px -1px 6px 0px rgb(0 0 0 / 40%); */
}

.input-group {
  width: 95%;
  /* border: 1px red solid; */
  margin: 0 auto;
  box-sizing: border-box;
}
.input {
  width: 100%;
  height: 40px;
  background-color: #05060f0a;
  border-radius: 0.5rem;
  padding: 0 1rem;
  border: 1px solid transparent;
  font-size: 1rem;
  transition: border-color 0.3s cubic-bezier(0.25, 0.01, 0.25, 1) 0s, color 0.3s cubic-bezier(0.25, 0.01, 0.25, 1) 0s,
    background 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;
  box-sizing: border-box;
  overflow: hidden;
}

.input:hover,
.input:focus,
.input-group:hover .input {
  outline: none;
  border-color: #ffffff;
  border: 1px solid #c1c1c1;
}

.input:focus {
  color: #05060fc2;
}
</style>
