<!--
 * @Author: 羚羊公子
 * @FilePath: \llKong\src\pages\Sendwz.vue
-->
<template>
  <div class="sendwz">
    <van-overlay :show="loading">
      <div class="wrapper">
        <div class="loader">
          <div id="first">
            <div id="second">
              <div id="third"></div>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
    <van-nav-bar title="发送文字空投" left-text="返回" left-arrow @click-left="onClickLeft" />
    <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="volume-o">
      根据相关要求，用户查看空投时需要显示昵称，可以在个人中心进行修改！
    </van-notice-bar>
    <div class="wbtarea">
      <el-input
        type="textarea"
        placeholder="请输入你要发的内容..."
        v-model="textarea"
        maxlength="1000"
        :rows="6"
        show-word-limit
      >
      </el-input>
    </div>
    <div class="send"><button @click="pres" class="custom-btn btn-2">保存</button></div>
    <div class="receive"><button @click="fanhui" class="custom-btn btn-3">返回</button></div>
    <van-action-sheet close-icon="close" v-model="show" title="空投发送成功">
      <div class="suess">
        <div class="qjm">你的取件码</div>
        <div class="qimnum">{{ qjm }}</div>
        <div class="ckkt">Tips: 可在首页我的个人中心查看空投详情</div>
        <div class="fxbtn" @click="toShare">
          <div class="plane-button">
            <input type="checkbox" name="checkbox" id="checkbox" />
            <button class="button">
              <div class="plane">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path fill="none" d="M0 0h24v24H0V0z"></path>
                  <path
                    style="fill: #091607; stroke-width: 1px; stroke: hsl(0deg 0% 100%)"
                    d="M21 14.58c0-.36-.19-.69-.49-.89L13 9V3.5c0-.83-.67-1.5-1.5-1.5S10 2.67 10 3.5V9l-7.51 4.69c-.3.19-.49.53-.49.89 0 .7.68 1.21 1.36 1L10 13.5V19l-1.8 1.35c-.13.09-.2.24-.2.4v.59c0 .33.32.57.64.48L11.5 21l2.86.82c.32.09.64-.15.64-.48v-.59c0-.16-.07-.31-.2-.4L13 19v-5.5l6.64 2.08c.68.21 1.36-.3 1.36-1z"
                  ></path>
                </svg>
              </div>
              <span class="loading">分享空投</span>
            </button>
          </div>
          <div class="fhsy" @click="fhsy">返回首页</div>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import { Notify } from "vant";
import { Base64 } from "js-base64";

export default {
  name: "Sendwz",
  data() {
    return {
      loading: false,
      show: false,
      textarea: "",
      qjm: "",
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    pres() {
      // console.log(Base64.decode(sessionStorage.getItem("ktkey")));
      if (this.textarea != "") {
        this.loading = true;
        let dataWz = {
          type: "text",
          content: this.textarea,
          key: Base64.decode(sessionStorage.getItem("ktkey")),
        };
        this.$axios
          .post("/request/send.php", dataWz, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(res => {
            console.log(res);
            this.loading = false;
            if (res.data.code == 500) {
              Notify({ type: "danger", message: `${res.data.msg}` });
            } else {
              this.show = true;
              this.loading = false;
              this.qjm = res.data.postid;
              sessionStorage.setItem("postid", res.data.postid);
              Notify({ type: "success", message: `${res.data.msg}` });
            }
          })
          .catch(err => {
            this.loading = false;
            this.show = true;
            // console.log(err);
            Notify({ type: "danger", message: `${err.data.msg}` });
          });
      } else {
        Notify({
          message: "文本框不能为空！！！",
          color: "white",
          background: "#f89809",
        });
      }
    },
    fanhui() {
      this.$router.go(-1);
    },
    fhsy() {
      this.$router.replace({
        name: "home",
      });
    },
    toShare() {
      // console.log(111);
      setTimeout(() => {
        this.$router.push({
          name: "share",
        });
      }, 3000);
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

/* From uiverse.io by @Navarog21 */
.loader {
  background-color: rgb(0, 0, 255);
  position: relative;
  width: 2.5em;
  height: 2.5em;
  transform-origin: center;
  transition: 1s;
  border-radius: 50px;
  box-shadow: inset 0px 0px 10px rgb(0, 62, 128), inset 5px 5px 12px rgba(0, 2, 114, 0.8),
    inset 8px 8px 1px rgba(120, 145, 255, 0.7), 0px 0px 1px rgba(120, 136, 255, 0.6);
  animation: 1.2s linear infinite 0s running first682;
}

.loader div {
  width: inherit;
  height: inherit;
  position: absolute;
}

#first {
  transform: rotate(90deg);
}

#first::before {
  --width: 1em;
  --height: 1em;
  content: "";
  position: absolute;
  top: 100%;
  left: calc(50% - var(--width) / 2);
  width: 1em;
  height: 1em;
  background-color: rgb(15, 5, 200);
  box-shadow: inset 5px 5px 10px rgb(99, 102, 255), 0px 0px 2px white;
  border-radius: 50px;
  animation: 0.8s ease-in 0s infinite running jump2;
}

#second {
  transform: rotate(90deg);
}

#second::before {
  --width: 1em;
  --height: 1em;
  content: "";
  position: absolute;
  top: 100%;
  left: calc(50% - var(--width) / 2);
  width: 1em;
  height: 1em;
  background-color: rgb(15, 5, 200);
  box-shadow: inset 5px 5px 10px rgb(99, 102, 255), 0px 0px 2px white;
  border-radius: 50px;
  animation: 1.5s ease-in 0s infinite running jump2;
}

#third {
  transform: rotate(90deg);
}

#third::before {
  --width: 1em;
  --height: 1em;
  content: "";
  position: absolute;
  top: 100%;
  left: calc(50% - var(--width) / 2);
  width: 1em;
  height: 1em;
  background-color: rgb(15, 5, 200);
  box-shadow: inset 5px 5px 10px rgb(99, 102, 255), 0px 0px 2px white;
  border-radius: 50px;
  animation: 1.6s ease-in 0s infinite running jump2;
}

.loader::after {
  --width: 1em;
  --height: 1em;
  content: "";
  position: absolute;
  top: 100%;
  left: calc(50% - var(--width) / 2);
  width: 1em;
  height: 1em;
  background-color: rgb(15, 5, 200);
  box-shadow: inset 5px 5px 10px rgb(99, 102, 255), 0px 0px 2px white;
  border-radius: 50px;
  animation: 1.2s ease-in 1s alternate infinite running jump2;
}

@keyframes first682 {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(0deg);
    transform: rotate(90deg);
  }

  50% {
    transform: rotate(90deg);
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(180deg);
    transform: rotate(270deg);
  }

  100% {
    transform: rotate(270deg);
    transform: rotate(360deg);
  }
}

@keyframes jump2 {
  0% {
    top: 100%;
  }

  25% {
    top: 230%;
  }

  50% {
    top: 100%;
  }

  75% {
    height: 0.6em;
  }

  100% {
    height: 1em;
  }
}

.sendwz {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #f6f6f6;
}

.van-action-sheet__header {
  font-weight: 600 !important;
  font-size: 18px !important;
  color: #151616e5 !important;
  letter-spacing: 2px;
}

.wbtarea {
  width: 95%;
  margin: 16px auto;
}
.send {
  width: 140px;
  margin: 20px auto;
}
.receive {
  width: 140px;
  margin: 10px auto;
}
.custom-btn {
  width: 140px;
  height: 45px;
  color: #fff;
  border-radius: 5px;
  padding: 10px 25px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5), 7px 7px 20px 0px rgba(0, 0, 0, 0.1),
    4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  font-size: 15px;
}

.btn-2 {
  background: #004dff;
  background: linear-gradient(0deg, #004dff 0%, #004dff 100%);
  border: none;
}

.btn-2:before {
  height: 0%;
  width: 2px;
}

.btn-2:active {
  box-shadow: 4px 4px 6px 0 rgb(255 255 255 / 50%), 1px -1px 20px 0 rgb(116 125 136 / 50%),
    inset -4px -4px 6px 0 rgb(255 255 255 / 20%), inset 4px 4px 6px 0 rgb(0 0 0 / 40%);
  /* box-shadow: 4px 4px 6px 0 rgb(255 255 255 / 50%), -1px -2px 2px 0px rgb(116 125 136 / 50%),
    inset 0px 0px 19px 0px rgb(255 255 255 / 20%), inset 0px -1px 6px 0px rgb(0 0 0 / 40%); */
}
.btn-3 {
  background: #f6f6f6;
  /* background: linear-gradient(0deg, #004dff 0%, #8da7e4 100%); */
  border: none;
  color: #1789fb;
}

.btn-3:before {
  height: 0%;
  width: 2px;
}

.btn-3:active {
  box-shadow: 4px 4px 6px 0 rgb(255 255 255 / 50%), 1px -1px 20px 0 rgb(116 125 136 / 50%),
    inset -4px -4px 6px 0 rgb(255 255 255 / 20%), inset 4px 4px 6px 0 rgb(0 0 0 / 40%);
  /* box-shadow: 4px 4px 6px 0 rgb(255 255 255 / 50%), -1px -2px 2px 0px rgb(116 125 136 / 50%),
    inset 0px 0px 19px 0px rgb(255 255 255 / 20%), inset 0px -1px 6px 0px rgb(0 0 0 / 40%); */
}
.suess {
  width: 100%;
  height: 200px;
}
.qjm {
  width: 100%;
  height: 20px;
  line-height: 20px;
  text-align: center;
  margin: 0 auto;
  color: #101011;
  font-size: 14px;
}
.qimnum {
  width: 100%;
  height: 35px;
  line-height: 35px;
  text-align: center;
  margin: 5px auto;
  /* border: 1px red solid; */
  box-sizing: border-box;
  overflow: hidden;
  font-size: 25px;
  font-weight: 600;
  color: #3593f8;
}
.ckkt {
  width: 100%;
  height: 20px;
  line-height: 20px;
  text-align: center;
  margin: 5px auto;
  /* border: 1px red solid; */
  font-size: 14px;
  color: rgb(222, 22, 22);
}
.fxbtn {
  width: 90%;
  height: 50px;
  /* border: 1px red solid; */
  display: flex;
  cursor: pointer;
  margin: 25px auto;
  justify-content: space-between;
}
.fhsy {
  width: 120px;
  height: 50px;
  border: 5px #ffffff solid;
  text-align: center;
  line-height: 40px;
  box-sizing: border-box;
  border-radius: 10px;
  border: solid 4px rgba(255, 255, 255, 0);
  background-clip: padding-box;
  box-shadow: 1px -1px 4px 0px rgb(39 94 254 / 50%);
  background-color: #1ebdf3;
  font-weight: 600;
  color: rgb(255, 255, 255);
  font-size: 16px;
  letter-spacing: 1px;
  user-select: none;
  cursor: pointer;
}
.fhsy:active {
  background-color: #2d5ecf;
}
.plane-button {
  position: relative;
  width: 130px;
  box-sizing: border-box;
  /* border: 1px red solid; */
  height: 50px;
  user-select: none;
  cursor: pointer;
}
.button {
  width: 130px;
  height: 50px;
  border-radius: 10px;
  border: solid 4px rgba(255, 255, 255, 0);
  background-clip: padding-box;
  box-shadow: 2px 0px 4px 0px rgb(39 94 254 / 50%);
  background-color: #0042db;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 100;
  cursor: pointer;
  color: white;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  z-index: 0;
  color: #f6f6f6;
  font-weight: 600;
}

@keyframes loading5234 {
  100% {
    opacity: 0;
  }
}

input:checked + .button {
  transition-delay: 2s;
  background-color: #31cb20;
  box-shadow: 2 0px 4px 0px rgb(49 203 32);
}

.title,
.approved {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  display: block;
  font-size: 15px;
}

.button .title {
  background-color: hsl(241deg 44% 49%);
  width: 90%;
}

input:checked + button .approved {
  transition-delay: 2s;
  visibility: visible;
  opacity: 1;
  background-color: #31cb20;
  width: 90%;
}

input:checked + .button .title {
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease;
}

input:not(:checked) + .button .approved {
  visibility: hidden;
  opacity: 1;
}

input {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  outline: none;
  width: 100px;
  height: 50px;
  border-radius: 20px;
  margin: 0;
  opacity: 0;
  cursor: pointer;
}

.plane {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  position: absolute;
  left: 0;
  z-index: 9;
  top: 0;
  transition: none;
  background-color: transparent;
  opacity: 0;
}

.plane svg {
  width: 45px;
  height: 45px;
  position: absolute;
  left: 0px;
  top: 0px;
  transform: rotate(90deg) translate(-50%, -40%) scale(1);
  transition: all 0s ease;
  fill: #3c4a57;
}

input:checked + .button .plane {
  animation: airplane-route 3s forwards;
  animation-timing-function: cubic-bezier(0, 0, 0, 0);
}

@keyframes airplane-route {
  0% {
    left: -300px;
    opacity: 1;
    transform: scale(2);
  }

  20% {
    left: 0px;
    transform: scale(1);
    opacity: 1;
  }

  40% {
    left: 100%;
    opacity: 1;
    transform: translateX(-100%);
  }

  60% {
    left: 100%;
    opacity: 1;
    transform: translateX(-100%) translateY(-6%) rotate(180deg);
  }

  80% {
    left: 0%;
    opacity: 1;
    top: -6px;
    transform: translateX(0%) rotate(180deg);
  }

  100% {
    left: 0%;
    opacity: 1;
    transform: translateX(0%) rotate(360deg);
  }
}
</style>
