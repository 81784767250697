<!--
 * @Author: 羚羊公子
 * @FilePath: \kongtou\src\pages\editor.vue
-->
<template>
  <div>
    <van-nav-bar title="个人详情" />
    <van-popup
      v-model="show1"
      round
      closeable
      close-icon="close"
      close-icon-position="top-right"
      position="top"
      :style="{ height: '30%' }"
    >
      <div class="popText">
        <div class="title">修改昵称</div>
        <div class="shuru">
          <input type="text" name="text" class="input" placeholder="输入您要修改的邮箱" />
        </div>
        <div class="weigsm">10个字以内，不得包含违规信息</div>
        <div class="btn">确定</div>
      </div>
    </van-popup>
    <van-popup
      v-model="show2"
      round
      closeable
      close-icon="close"
      close-icon-position="top-right"
      position="top"
      :style="{ height: '30%' }"
    >
      <div class="popText">
        <div class="title">修改邮箱</div>
        <div class="shuru">
          <input type="email" name="text" class="input" placeholder="输入您要修改的昵称" />
        </div>
        <div class="weigsm">注意邮箱的格式哦！</div>
        <div class="btn">确定</div>
      </div>
    </van-popup>
    <van-popup
      v-model="show3"
      round
      closeable
      close-icon="close"
      close-icon-position="top-right"
      position="top"
      :style="{ height: '30%' }"
    ></van-popup>
    <div @click="zx">编辑页</div>
  </div>
</template>

<script>
export default {
  name: "editor",
  data() {
    return {
      show1: false,
      show2: false,
      show3: false,
    };
  },
  methods: {
    zx() {
      this.show2 = true;
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.popText {
  width: 100%;
  height: 100%;
  /* border: 1px red solid; */
  overflow: hidden;
  box-sizing: border-box;
}
.title {
  width: 60%;
  height: 40px;
  margin: 0 auto;
  /* border: 1px red solid; */
  text-align: center;
  line-height: 40px;
  font-size: 18px;
  color: rgb(0, 0, 0);
  font-weight: 600;
  padding-top: 10px;
}

.shuru {
  width: 90%;
  height: 30px;
  margin: 0 auto;
  /* overflow: hidden; */
  box-sizing: border-box;
}
.input {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 4px;
  box-shadow: 0px 0px 1px 0 rgb(0 0 0 / 20%);
  outline: none;
  color: dimgray;
  box-sizing: border-box;
  text-align: center;
}
.weigsm {
  width: 60%;
  height: 20px;
  margin: 20px auto;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
  letter-spacing: 1px;
  color: #c1c1c1;
}
.btn {
  width: 100px;
  height: 35px;
  margin: 0 auto;
  background-color: rgb(20, 90, 219);
  color: rgb(255, 255, 255);
  text-align: center;
  line-height: 35px;
  border-radius: 6px;
  cursor: pointer;
  user-select: none;
  box-shadow: 0px 0px 8px 2px rgb(22 130 216 / 23%);
}
</style>
